'use client';
import { useCallback, useEffect } from 'react';

import { useRouter } from 'next/navigation';

import { ReactComponent as Logo } from '@quick/assets/logos/quick.svg';

import { useCountry } from '@/layers/core/deal/services/CountryContext';
import { useFetch } from '@/layers/core/deal/services/useFetch';

function HomeRedirector() {
  const { replace, prefetch } = useRouter();
  const { lang, id } = useCountry();

  useEffect(() => {
    prefetch(`/${lang}`);
  }, [prefetch, lang]);

  const next = useCallback(() => replace(`/${lang}`), [lang, replace]);
  // Populate SWR cache for next page
  const {} = useFetch({
    url: `/services/country/lines/${id}`,
    auth: true,
    params: { user_id: undefined },
  });

  useEffect(() => {
    // It should be handled by onAnimationEnd, but in case there is a buggy browser
    const t = setTimeout(next, 10_000);
    return () => clearTimeout(t);
  }, [next]);

  return (
    <div
      className="animate-in fade-in-0 flex h-full w-full items-center justify-center
        duration-1000 will-change-[opacity]"
      onAnimationEnd={next}
    >
      <Logo height={96} />
    </div>
  );
}

export default HomeRedirector;
